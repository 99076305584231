// vendors
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { Helmet } from 'react-helmet';
// views
import { FormattedMessage, useIntl } from 'react-intl';
import OutlinedButton from '../components/Button/OutlinedButton';
// Styles
import UnderlinedButton, {
  classes as LinkClassNames,
} from '../components/Button/UnderlinedButton';
import InView from '../components/InView/InVew';
// components
import Layout from '../components/Layout/Layout';
import AubergeLogotype from '../components/Logo/AubergeLogotype';
import AubergePictogram from '../components/Logo/AubergePictogram';
import Text from '../components/Text';
import Title from '../components/Title';
import VideoSection from '../components/VideoSection';
// Videos
import intro700mp4 from '../videos/introAuberge700k.mp4';
import HomePageAbout from '../views/HomePageAbout';
import HotelCarousel from '../views/HotelCarousel/HotelCarousel';
import ScrollingText from '../views/ScrollingText';
import PopupComponent from '../components/PopupComponent/PopupComponent';
import { convertContentToString } from '../libraries/ContentToString';
import CookieConsentCSS from '../components/CookieConsent/CookieConsentCSS';
import CookieConsent, {
  doNecessaryCookieAuthorized,
  doAnalyticCookieAuthorized,
  doAdsCookieAuthorized,
} from '../components/CookieConsent/CookieConsent';


const HistoryText = (
  <Text className="mx-auto max-w-prose">
    <InView>
      <Title level={3}>Plus de 100 ans d’histoire</Title>
    </InView>

    <InView>
      <p>
        Construite en 1878, alors que le village n’avait encore que 16 ans
        d’existence, l’Auberge sur Mer a été un témoin privilégié de l’histoire
        de Notre-Dame-du-Portage.
      </p>
    </InView>
  </Text>
);

const WhatToDoText = (
  <>
    <InView>
      <Title level={3}>Quoi faire à Notre-Dame-du-Portage?</Title>
    </InView>

    <Text className="max-w-prose">
      <InView>
        <p>
          La marche et le vélo sont les moyens de transport par excellence pour
          partir à la découverte des environs. Tout au long de la Route verte,
          vous croiserez des points de vue admirables qui plongeront votre
          regard vers l’immensité du fleuve et de sa diversité faunique et
          florale.
        </p>

        <p>
          La plage publique de Notre-Dame-du-Portage est un joyau du village et
          la piscine municipale est réputée pour son eau salée et chauffée.
        </p>

        <p>
          Pour plus d’infos, visitez:{' '}
          <a href="https://notredameduportage.ca" className={LinkClassNames}>
            notredameduportage.ca
          </a>
        </p>
      </InView>
    </Text>
  </>
);

const StoreText = (
  <>
    <Title level={3}>Boutique</Title>

    <Text>
      <StaticImage
        src="../images/auberge/Auberge-sur-mer-18.jpg"
        width={832}
        className="m-8 lg:float-right lg:m-4 lg:mt-0 lg:mr-0 lg:max-w-[448px] lg:fluid-w-[18/28]"
        sizes="(max-width: 1023px) 100vw,(max-width: 1920px) 50vw, 832px"
        alt={``}
      />
      <p>
        L’auberge sur mer vous offre une petite boutique où vous pourrez
        retrouver des souvenirs de notre région et fabriqués en grande majorité
        par des producteurs locaux. Que ce soit de petites gourmandises, une
        variété de produits de beauté ou différents articles pour décorer votre
        cuisine ou votre maison, vous saurez certainement y dénicher quelques
        items qui sauront vous plaire et vous remémorer votre passage chez nous.
      </p>
    </Text>
  </>
);

const alternativesLanguages = [
  {
    locale: 'en',
    href: '/en',
  },
];

function IndexPage({ data }) {
  const intl = useIntl();

  const popupMessage = convertContentToString(data.businessCloseMessage?.portableText);

  return (
    <Layout className="pb-40" alternativesLanguages={alternativesLanguages}>
      <CookieConsentCSS></CookieConsentCSS>

      <CookieConsent langcode="fr"></CookieConsent>
      {popupMessage.length > 10 && <PopupComponent message={popupMessage} />}


      <Helmet>
        <link rel="preload" href={intro700mp4} as="video" type="video/mp4" />
      </Helmet>

      <div className="text-center">
        <h1 className="inline-block px-12 mt-32 mb-16 max-w-[32rem] md:mt-16">
          <span className="sr-only">Auberge sur Mer</span>
          <AubergeLogotype className="w-full" role="presentation" />
        </h1>
      </div>

      <div className="container mx-auto">
        <VideoSection desktopMp4={intro700mp4} />
      </div>

      <section
        id={intl.formatMessage({ defaultMessage: 'a-propos' })}
        className="my-40 mx-auto space-y-14 max-w-prose font-title text-center scroll-mt-24 px-container fluid-text-[1.25/3]"
      >
        <InView className="mx-auto w-20">
          <AubergePictogram animate />
        </InView>

        <InView>
          <p>
            Auberge centenaire située directement sur le fleuve Saint-Laurent,
            dans le magnifique village de Notre-Dame-du-Portage, tout près de
            Rivière-du-Loup, à la croisée des chemins vers les Maritimes et
            la&nbsp;Gaspésie. Admirez un coin de pays à faire rêver,
            parcourez-le à pied ou à vélo. Profitez d’une expédition vers les
            fascinantes îles du St-Laurent… ou installez-vous face au fleuve et
            relaxez…
          </p>
        </InView>
      </section>

      <HomePageAbout
        historyText={HistoryText}
        whatToDoText={WhatToDoText}
        storeText={StoreText}
      />

      <div className="my-[25vh]">
        <ScrollingText />
      </div>

      <section
        id={intl.formatMessage({ defaultMessage: 'hebergement' })}
        className="scroll-mt-24"
      >
        <div className="container mx-auto text-center">
          <InView>
            <Title level={2}>Hébergement</Title>
          </InView>

          <InView>
            <OutlinedButton
              href="https://secure.reservit.com/fo/booking/58/203884?specialMode=default&langcode=FR&partid=0&custid=58&hotelid=203884&m=booking"
              target="_blank"
            >
              <FormattedMessage defaultMessage="Chambres et tarifs" />
            </OutlinedButton>
          </InView>
        </div>

        <InView className="fluid-my-[4/6]">
          <HotelCarousel />
        </InView>

        <InView className="container flex flex-col gap-8 justify-between items-center mx-auto md:flex-row md:items-start fluid-my-[4/8]">
          <Text className="flex-1 max-w-prose text-center">
            <Title level={3}>Au motel</Title>

            <p>
              À deux pas de l’auberge, les motels offrent des balcons privés
              avec une vue splendide sur le fleuve.
            </p>

            <UnderlinedButton href="https://secure.reservit.com/fo/booking/58/203884?specialMode=default&langcode=FR&partid=0&custid=58&hotelid=203884&m=booking">
              Réserver
            </UnderlinedButton>
          </Text>

          <Text className="flex-1 max-w-prose text-center">
            <Title level={3}>À l’auberge</Title>

            <p>
              Chambres dans l’auberge, au 2<sup>e</sup> et 3<sup>e</sup> étage
              d’un bâtiment au charme centenaire.
            </p>

            <UnderlinedButton href="https://secure.reservit.com/fo/booking/58/203884?specialMode=default&langcode=FR&partid=0&custid=58&hotelid=203884&m=booking">
              Réserver
            </UnderlinedButton>
          </Text>

          <Text className="flex-1 max-w-prose text-center">
            <Title level={3}>Au chalet</Title>

            <p>
              Situés à quelques pas de l’auberge et directement sur le fleuve,
              les chalets offrent un hébergement complet très spacieux incluant
              deux chambres, une cuisine complète et une grande terrasse avec
              vue partielle sur le fleuve. C’est l’option idéale pour vous
              permettre de profiter de toutes les commodités de l’auberge tout
              en vous offrant plus d’intimité et d’autonomie.
            </p>

            <UnderlinedButton href="https://secure.reservit.com/fo/booking/58/203884?specialMode=default&langcode=FR&partid=0&custid=58&hotelid=203884&m=booking">
              Réserver
            </UnderlinedButton>
          </Text>
        </InView>
      </section>

      <div className="container mx-auto fluid-my-[4/8]">
        <StaticImage
          width={1664}
          src="../images/auberge/Auberge-sur-mer-21.jpg"
          role="presentation"
          sizes="(max-width: 1920px) 100vw, 1664px"
          className="w-full"
          alt={``}
        />
      </div>

      <section
        id={intl.formatMessage({ defaultMessage: 'restaurant' })}
        className="container gap-x-4 mx-auto lg:flex lg:justify-start lg:items-center fluid-my-[4/8]"
      >
        <Text className="text-center lg:text-left">
          <InView>
            <Title level={2}>Restaurant</Title>
          </InView>

          <InView>
            <p>
              Le April vous propose une cuisine inspirée par les produits locaux
              et de saison
            </p>
          </InView>

          <InView className="fluid-pt-[1.5/3]">
            <OutlinedButton to="/restaurant">
              <FormattedMessage defaultMessage="En savoir plus" />
            </OutlinedButton>
          </InView>
        </Text>

        <Link to="/restaurant" className="block my-12 lg:hidden">
          <StaticImage
            src="../images/restaurant-april-mobile.png"
            className="block mx-auto"
            width={904}
            sizes="(max-width: 1024px) 100vw, 904px"
            alt={``}
          />
        </Link>

        <Link
          to="/restaurant"
          className="hidden grow my-0 text-center lg:block"
        >
          <StaticImage
            src="../images/restaurant-april-desktop.png"
            width={768}
            className="block mx-auto fluid-w-[28/40]"
            sizes="(max-width: 1920px) 50vw, 640px"
            alt={``}
          />
        </Link>
      </section>
    </Layout>
  );
}

export default IndexPage;


IndexPage.propTypes = {
  data: PropTypes.shape({
    breakfastMenu: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
    dinerMenu: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
    snackMenu: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
    
    businessCloseMessage: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }),
    privacyRules: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }),
    cookieRules: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }),
    openingHours: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }),
  }).isRequired,
};

export const query = graphql`
  query {
    breakfastMenu: sanityBreakfastMenu(_id: { eq: "breakfastMenu" }) {
      portableText: _rawFr(resolveReferences: { maxDepth: 10 })
    }
    dinerMenu: sanityDinerMenu(_id: { eq: "dinerMenu" }) {
      portableText: _rawFr(resolveReferences: { maxDepth: 10 })
    }
    snackMenu: sanitySnackMenu(_id: { eq: "snackMenu" }) {
      portableText: _rawFr(resolveReferences: { maxDepth: 10 })
    }
    businessCloseMessage: sanityBusinessCloseMessage(_id: { eq: "businessCloseMessage" }) {
      portableText: _rawFr(resolveReferences: { maxDepth: 10 })
    }
    privacyRules: sanityPrivacyRules(_id: { eq: "privacyRules" }) {
      portableText: _rawFr(resolveReferences: { maxDepth: 10 })
    }
    cookeyRules: sanityCookieRules(_id: { eq: "cookieRules" }) {
      portableText: _rawFr(resolveReferences: { maxDepth: 10 })
    }
    openingHours: sanityOpeningHours(_id: { eq: "openingHours" }) {
      portableText: _rawEn(resolveReferences: { maxDepth: 10 })
    }
  }
`;

/*

      */